<template>
  <div class="cash">
    <div class="box">
      <div class="menu flex jc_s">
          <a href="/cardCoucher" class="flex jc_s now"
              ><i class="iconfont iconmaijia_wode"></i>满减券</a
          >
          <a href="/cardcoucherDH" class="flex jc_s"
              ><i class="iconfont iconyonghu1"></i>兑换券</a
          >
          <a href="/cardcoucherGB" class="flex jc_s "
              ><i class="iconfont icontuiguang-1"></i>贵宾券</a
          >
          <a href="/cardcoucherDJ" class="flex jc_s"
              ><i class="iconfont iconkehu"></i>代金券</a
          >
      </div>
      <div class="p16">
        <div class="pb16 flex jc_b ai_c">
          <el-button type="primary" class="ssbtn" style="width: 6.75rem" @click="addBtn">添加</el-button>
          <div class="flex jc_end f14 col333 ai_c">
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="valtable1" label="名称"  width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.title }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="out90" label="起始卡号">
              <template slot-scope="scope">
                <div>{{ scope.row.card_number_start }}</div>
              </template>
            </el-table-column> -->
            <el-table-column prop="cname" label="批次"  width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.batch }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="addr" label="类别" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.card_type == 1">满减卡</div>
                <div v-else-if="scope.row.card_type == 2">兑换卡</div>
                <div v-else>贵宾卡</div>
              </template>
            </el-table-column> -->
            <el-table-column prop="in20" label="金额">
              <template slot-scope="scope">
                <div>{{ scope.row.money }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="from30to40" label="手动领取">
              <template slot-scope="scope">
                <div>{{ scope.row.is_manual == 1 ? '是' : '否' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="from40to50" label="使用条件">
              <template slot-scope="scope">
                <div>{{ scope.row.condition }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="out90" label="限定城市">
              <template slot-scope="scope">
                <div>{{ scope.row.city }}</div>
              </template>
            </el-table-column> -->
            <el-table-column prop="from80to90" label="有效期" width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.validity_time ? scope.row.validity_time : '-' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="制卡数量">
              <template slot-scope="scope">
                <div>{{ scope.row.card_quantity }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="已领数量">
              <template slot-scope="scope">
                <div>{{ scope.row.received_quantity }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="已用数量">
              <template slot-scope="scope">
                <div>{{ scope.row.card_use_quantity }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="最大领取数量" width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.card_user_quantity }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="弹窗">
              <template slot-scope="scope">
                <div>{{ scope.row.cpm == 1 ? '是' : '否' }}</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" width="240">
              <template slot-scope="scope">
                <el-button class="qxbtn mr10" type="info" @click="infoBtn(scope.row)">查看</el-button>
                <el-button class="qxbtn mr10" @click="editBtn(scope.row)">编辑</el-button>
                <el-button class="qxbtn mr10" type="danger" @click="delBtn(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--table end-->

        <!-- 添加  编辑 -->
        <!-- dialog -->
        <el-dialog class="dialogHeaderno" width="625px" :visible.sync="dialogVisible" :before-close="handleClose">
          <div class="qxDialogHeader flex-column jc_c">
            <div class="flex jc_b ai_c">
              <span class="f16 colfff">{{ title }}</span>
              <i class="iconfont iconguanbi colfff f18" @click="dialogVisible = false"></i>
            </div>
          </div>
          <div class="ccmid">
            <el-form ref="form" :model="form">
              <!-- <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">类别：</span>
                <el-form-item>
                  <el-radio-group v-model="form.card_type" :disabled="title == '添加' ? false : true " class="xzradio">
                    <el-radio :label="1">满减卡</el-radio>
                    <el-radio :label="2">兑换卡</el-radio>
                    <el-radio :label="3">贵宾卡</el-radio>
                    <el-radio :label="4">代金券</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div> -->
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">名称：</span>
                <el-form-item prop="title">
                  <el-input placeholder="输入名称" v-model="form.title" auto-complete="off"></el-input>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">批次：</span>
                <el-form-item prop="batch">
                  <el-input placeholder="输入批次" v-model="form.batch" auto-complete="off"></el-input>
                </el-form-item>
              </div>

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">金额：</span>
                <el-form-item label="" prop="money">
                  <el-input placeholder="输入金额" v-model="form.money"></el-input>
                </el-form-item>
              </div>



              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">领取人：</span>
                <el-form-item prop="movie">
                  <!-- <el-select filterable v-model="form.receiver" multiple collapse-tags placeholder="请选择">
                    <el-option v-for="(item, index) in slect3" :key="index" :label="item.nickname" :value="item.id"> </el-option>
                  </el-select> -->

                  <el-select v-model="form.receiver" multiple filterable remote reserve-keyword placeholder="选择领取人id，不选为全部" :remote-method="remoteMethod1" :loading="loading">
                    <el-option v-for="item in slect3" :key="item.value" :label="item.id" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
              
              <div  class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">使用条件：</span>
                <el-form-item label="" prop="condition">
                  <el-input v-model="form.condition"></el-input>
                </el-form-item>
              </div>
              
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">限定影城：</span>
                <el-form-item prop="movie">
                  <!-- <el-select filterable v-model="form.movie" multiple collapse-tags placeholder="请选择">
                    <el-option v-for="(item, index) in slect2" :key="index" :label="item.nm" :value="item.cinemaid"> </el-option>
                  </el-select> -->
                  <el-select v-model="form.movie" multiple filterable remote reserve-keyword placeholder="选择影城，不选为全部" :remote-method="remoteMethod2" :loading="loading">
                    <el-option v-for="item in slect2" :key="item.value" :label="item.nm" :value="item.cinemaid"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">指定影片：</span>
                <el-form-item prop="film">
                  <!-- <el-select filterable v-model="form.film" multiple collapse-tags placeholder="请选择">
                    <el-option v-for="(item, index) in slect1" :key="index" :label="item.nm" :value="item.mid"> </el-option>
                  </el-select> -->
                  <el-select v-model="form.film" multiple filterable remote reserve-keyword placeholder="选择影片，不选为全部" :remote-method="remoteMethod3" :loading="loading">
                    <el-option v-for="item in slect1" :key="item.value" :label="item.nm" :value="item.mid"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">有效期：</span>
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" v-model="form.validity_time" type="datetime" placeholder="选择日期时间"> </el-date-picker>
              </div>
              <div   class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">制卡数量：</span>
                <el-input-number size="small" v-model="form.card_quantity" @change="handleChange" label=""></el-input-number>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">最大领取数量：</span>
                <el-input-number size="small" v-model="form.card_user_quantity" @change="handleChange" label=""></el-input-number>
              </div>


              <div  class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">手动领取：</span>
                <el-form-item prop="is_manual">
                  <el-radio-group v-model="form.is_manual" class="xzradio">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div  class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">弹窗：</span>
                <el-form-item prop="cpm">
                  <el-radio-group v-model="form.cpm" class="xzradio">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-form>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="cancle">取 消</el-button>
            <el-button type="primary" @click="sure">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 详情 -->
        <el-dialog class="dialogHeaderno" width="800px" :visible.sync="dialogInfo" :before-close="handleClose">
          <div class="qxDialogHeader flex-column jc_c">
            <div class="flex jc_b ai_c">
              <span class="f16 colfff">详情</span>
              <i class="iconfont iconguanbi colfff f18" @click="dialogInfo = false"></i>
            </div>
          </div>
          <div class="info_box" style="padding: 20px 80px">
            <div class="info_item">
              <div>名称：{{ details.title }}</div>
              <div>批次：{{ details.batch }}</div>
            </div>
            <div class="info_item">
              <div >类别：满减卡</div>
              <!-- <div v-if="details.card_type == 2">类别：兑换卡</div>
              <div v-if="details.card_type == 3">类别：贵宾卡</div> -->
              <div>金额：{{ details.money }}</div>
            </div>
            <div class="info_item">
              <div>手动领取：{{ details.is_manual == 1 ? '是' : '否' }}</div>
              <div>使用条件：{{ details.condition }}</div>
            </div>
            <div class="info_item">
              <div>有效期：{{ details.validity_time }}</div>
              <div>制卡数量：{{ details.card_quantity }}</div>
            </div>
            <div>弹窗：{{ details.cpm == 1 ? '是' : '否' }}</div>
            <div>领取人：{{ details.receiver_name }}</div>
            <div>限定影城：{{ details.movie_name }}</div>
            <div>指定影片：{{ details.film_name }}</div>
          </div>
        </el-dialog>

        <div class="page pt16">
          <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="total"> </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      city_list:[],
      dialogVisible: false,
      dialogInfo: false,
      form: {
        title: '',
        batch: '',
        card_type: 1,
        money: '',
        receiver: '',
        is_manual: 1,
        condition: '',
        movie: '',
        card_quantity: 0,
        film: '',
        cpm: 1,
        expiration_day: 0,
        validity_time: '',
        card_user_quantity: 0,

        // card_number_start:'',
        // tp:0,
        // is_yici:0,
        // city:[],
        // remarks:''
      },
      slect1: [],
      slect2: [],
      slect3: [],
      title: '添加',
      details: {},

      valSelect: '',
      select: {
        dataType: '',
        cinameType: '',
        searchType: '',
        valSerch: '',
      },
      selectOption: {
        dataOption: [
          {
            value: '2',
            label: '全部',
          },
          {
            value: '1',
            label: '旧数据',
          },
          {
            value: '0',
            label: '新数据',
          },
        ],
        cinameOption: [
          {
            value: '2',
            label: '全部',
          },
          {
            value: '1',
            label: '连锁',
          },
          {
            value: '0',
            label: '非连锁',
          },
        ],
        searchOption: [
          {
            value: '1',
            label: '影院',
          },
          {
            value: '2',
            label: '城市',
          },
          {
            value: '3',
            label: '地址',
          },
        ],
      },
      // 表格数据
      tableData: [],
      // 搜索条件
      condition: {
        limit: 10,
        offset: 0,
        tp: '',
        is_old: '',
        nm: '',
        addr: '',
        cname: '',
      },
      item: '',
      pageSize: 10,
      currentPage: 1,
      total: 0,
      plczDialog: false,
      bjxgDialog: false,
      batch: {
        nm: '',
        tp: '',
        in20: '0.95',
        from20to30: '0.925',
        from30to40: '0.92',
        from40to50: '0.915',
        from50to60: '0.91',
        from60to70: '0.895',
        from70to80: '0.89',
        from80to90: ' 0.885',
        out90: '0.88',
      },

      loading: false,
    }
  }, //data
  created() {
    this.getData()
    this.getCity()
    // this.getSelect1()
    // this.getSelect2()
    // this.getSelect3()
  },
  methods: {
    async remoteMethod1(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true
        await this.getSelect3(query)

        this.loading = false
      } else {
        this.options = []
      }
    },
    async remoteMethod2(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true

        await this.getSelect2(query)

        this.loading = false
      } else {
        this.options = []
      }
    },
    async remoteMethod3(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true

        await this.getSelect1(query)

        this.loading = false
      } else {
        this.options = []
      }
    },

    handleChange(value) {
      console.log(value)
    },
    handleClose() {},
    // 弹窗取消
    cancle() {
      this.dialogVisible = false
    },
    // 弹窗确定
    sure() {
      if (this.title == '添加') {
        this.$api.sysSetCard(this.form).then((res) => {
          if (res.data.code == 0) {
            this.dialogVisible = false
            this.$message({
              type: 'success',
              message: '添加成功!',
            })
            this.getData()
          }
        })
      } else {
        this.$api.sysSetCard(this.form).then((res) => {
          if (res.data.code == 0) {
            this.dialogVisible = false
            this.$message({
              type: 'success',
              message: '编辑成功!',
            })
            this.getData()
          }
        })
      }
    },

    filter(val) {
      let offset = (val - 1) * this.pageSize
      let data = {
        limit: this.pageSize,
        offset: offset,
        card_type: 1
      }
      return data
    }, //filter
    // 获取数据
    getData(val = 1) {
      const that = this
      let data = this.filter(val)
      this.$api.sysGetCard(data).then((res) => {
        if (res.data.code == 0) {
          that.total = res.data.data.count
          that.tableData = res.data.data.rows
        }
      })
    },
    // 获取影片
    async getSelect1(nm) {
      let res = await this.$api.sysGetData({
        nm,
      })
      if (res.data.code == 0) {
        this.slect1 = res.data.data
        // this.slect1.unshift({
        //   nm: '全部',
        //   mid: '',
        // })
      }
    },
    // 获取影城
    async getSelect2(nm) {
      let res = await this.$api.sysGetCinema({
        nm,
      })
      if (res.data.code == 0) {
        this.slect2 = res.data.data
        // this.slect2.unshift({
        //   nm: '全部',
        //   cinemaid: '',
        // })
      }
    },
    // 获取用户
    async getSelect3(id) {
      let res = await this.$api.sysGetUser({
        id,
      })

      if (res.data.code == 0) {
        this.slect3 = res.data.data
        // this.slect3.unshift({
        //   nickname: '全部',
        //   id: '',
        // })
      }
    },

    handleCurrentChange(val) {
      this.currentPage = val
      this.getData(val)
    },
    // 添加
    addBtn() {
      this.dialogVisible = true
      this.title = '添加'
      this.form = {
        ...this.form,
        title: '',
        batch: '',
        card_type: 1,
        money: '',
        receiver: '',
        is_manual: 1,
        condition: '',
        movie: [],
        card_quantity: 0,
        film: [],
        cpm: 1,
        expiration_day: 0,
        validity_time: '',
        card_user_quantity: 0,

        // card_number_start:'',
        // tp:0,
        // is_yici:0,
        // city:[],
        // remarks:''
      }
    },
    // 详情
    infoBtn({ id }) {
      this.dialogInfo = true
      this.$api.getCardDetail({ card_id: id }).then((res) => {
        this.details = res.data.data
      })
    },
    // 编辑
    editBtn(obj) {
        
      console.log(obj)
      this.dialogVisible = true
      this.title = '编辑'
      this.form = obj
    },
    delBtn(obj) {
      this.$confirm('正在进行删除操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(obj)
          this.$api.sysSetCard({ ...obj, is_deleted: 1 }).then((res) => {
            if (res.data.code == 0) {
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.currentPage = 1
              this.getData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getCity(){
      
      this.$api.getCityLinkage().then((res) => {
        console.log('res',res)
        if (res.data.code == 0) {
          res.data.data.map((item,index)=>{
            item.children.map((ite,idx)=>{
              ite.cid = ite.rid
              ite.cname = ite.name
            })
          })
          this.city_list = res.data.data
        }
      })
    },

    // 搜索按钮
    searchBtn() {
      // this.currentPage = 1
      // this.condition.tp = this.select.cinameType
      // this.condition.is_old = this.select.dataType
      // switch (Number(this.select.searchType)) {
      // 	case 1:
      // 		this.condition.cname = ''
      // 		this.condition.addr = ''
      // 		this.condition.nm = this.select.valSerch
      // 		break;
      // 	case 2:
      // 		this.condition.nm = ''
      // 		this.condition.addr = ''
      // 		this.condition.cname = this.select.valSerch
      // 		break;
      // 	case 3:
      // 		this.condition.nm = ''
      // 		this.condition.cname = ''
      // 		this.condition.addr = this.select.valSerch
      // 		break;
      // 	default:
      // 		this.condition.nm = ''
      // 		this.condition.cname = ''
      // 		this.condition.addr = ''
      // 		this.select.valSerch = ''
      // }
      // // console.log(this.condition)
      // this.getData()
    },

    // 编辑按钮
    editConfirm() {
      //  this.$confirm('是否继续修改该配置?', '提示', {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消',
      // 	type: 'warning'
      // }).then(() => {
      // 	let data = {
      // 		id:this.item.id,
      // 		tp:this.item.tp,
      // 		in20:this.item.in20,
      // 		from20to30:this.item.from20to30,
      // 		from30to40:this.item.from30to40,
      // 		from40to50:this.item.from40to50,
      // 		from50to60:this.item.from50to60,
      // 		from60to70:this.item.from60to70,
      // 		from70to80:this.item.from70to80,
      // 		from80to90:this.item.from80to90,
      // 		out90:this.item.out90,
      // 	}
      // 	this.$api.setCinemaList(data).then(res => {
      // 		this.bjxgDialog = false
      // 		// console.log(res.data)
      // 		if(res.data.code == 0){
      // 			this.$message({
      // 				type:'success',
      // 				message:'修改成功'
      // 			})
      // 			this.getData()
      // 		}
      // 	}).catch(err => {
      // 		console.log(err)
      // 	})
      // }).catch(() => {
      // 	this.$message({
      // 		type: 'info',
      // 		message: '已取消删除'
      // 	});
      // })
    },
    // 批量操作按钮
    batchConfirm() {
      // 	this.$confirm('是否继续修改该配置?', '提示', {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消',
      // 	type: 'warning'
      // }).then(() => {
      // 	if(this.batch.tp == 2){
      // 		this.batch.tp = ''
      // 	}
      // 	this.$api.setSomeCinema(this.batch).then(res => {
      // 		this.plczDialog = false
      // 		if(res.data.code == 0){
      // 			this.$message({
      // 				type:'success',
      // 				message:'修改成功'
      // 			})
      // 			this.getData()
      // 		}
      // 		}).catch(err => {
      // 			console.log(err)
      // 		})
      // }).catch(() => {
      // 	this.$message({
      // 		type: 'info',
      // 		message: '已取消删除'
      // 	});
      // })
    },
  }, //methods
}
</script>

<style lang="less" scoped>
.info_box {
  div {
    height: 60px;
    text-align: left;
  }
  .info_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      flex: 1;
    }
  }
}
// .el-form-item {
//   margin-bottom: 10px;
// }
// /deep/ .el-table_1_column_15 .cell {
//   overflow: visible;
// }
// /deep/ .el-input--suffix .el-input__inner {
// 	color: #409EFF !important;
// }
// /deep/ .el-input--suffix .el-input__inner{
// 	border-color: #ccc !important;
// }
</style>